import React, { useState, useRef } from 'react';
import { dados } from './dados';
import './styles/style.css'

export default function Ingles() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [randomPic, setRandomPic] = useState(Math.floor(Math.random() * 3)+1)
  const modalContentRef = useRef(null);

  const openModal = (dataId) => {
    setSelectedData(dados[dataId]);
    setIsModalOpen(true);
  };

  const closeModal = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className='modal-border'>
            <div className="modal-content" ref={modalContentRef}>
              <div className='header'>
                <img className="imagem" src={selectedData[0]} alt={selectedData[1]} />
                <div className="titulo">{selectedData[1]}</div>
              </div>

              {/* <div className='nivel-de-conhecimento'>
                <div className="titulo">skill level:</div>
                <img className="estrelas" src={selectedData[2]} alt="estrelas" />
              </div> */}

            {selectedData[3].length > 0 ?
              <div className='certificados'>
                <div className="titulo">certificates:</div>
                <ul>
                  {selectedData[3].map((certificado, idx) => (
                    <li key={idx}><a href={certificado} target="_blank" rel="noreferrer">{`Certificate nº${idx + 1}`}</a></li>
                  ))}
                </ul>
              </div> : null}

              <div className='projetos-modal'>
                <div className="titulo">projects:</div>
                <ul>
                  {selectedData[4].map((projeto, idx) => (
                    <li key={idx}><a href={projeto[1]} target="_blank" rel="noreferrer">{projeto[0]}</a></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="home">
        <a class="ingles" href="/"><img src="./assets/icones/brasil.png" alt="pt-BR" /></a>
        {/*<div class="texto">Hello! I'm Caio</div>*/}
        <div class="icones">
          <a target="_blank" rel="noreferrer" href="https://twitter.com/caioagralemos"
          ><img src="./assets/icones/twitter.svg" alt="twitter"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://github.com/caioagralemos"
          ><img src="./assets/icones/github.svg" alt="github"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/caioagralemos"
          ><img src="./assets/icones/linkedin.svg" alt="linkedin"
            /></a>
        </div>
        <img src={"./assets/profile" + randomPic + ".webp"} class="profile" alt="profile" />
      </div>
      <div class="sobremim">
        <div class="titulo">ABOUT ME</div>
        <div class="informacoes">
          <div class="info">
            <img src="./assets/icones/pc.png" alt="pc" />I've learned to read when I was 3, and have been, since then,
            in love with tecnology, computing and design.
          </div>
          <div class="info">
            <img src="./assets/icones/hobbies.png" alt="hobbies" />Also passionate for traveling, gaming, sports and music, I see creativity and innovation as my most valuable skills.
          </div>
          <div class="info">
            <img src="./assets/icones/college.png" alt="college" />I'm a Computer Science Student
            at UFAL and I have more then 30 extra-curricular certificates.
          </div>
          <div class="info">
            <img src="./assets/icones/ssc.png" alt="swift student challenge" />I was recognized by Apple as one of the world's best iOS students <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=iuo-ZkgB_Eg&list=PLBCWDKqK8_I14sLrbM8ZH7gHS9tUkKOA_"
          >at WWDC 2024</a> with "Potoca".
          </div>
        </div>
      </div>
      <div class="conhecimentos">
        <div class="titulo">MY SKILLS</div>
        <div class="subtitulo">click the icons to learn more</div>
        <div className="gridContainer">
          <button className="conhecimento" onClick={() => openModal(0)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/django.png" alt="Django" />
          </button>
          <button className="conhecimento" onClick={() => openModal(1)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/express.webp" alt="Express" />
          </button>
          <button className="conhecimento" onClick={() => openModal(2)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/figma.png" alt="Figma" />
          </button>
          <button className="conhecimento" onClick={() => openModal(3)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/firebase.webp" alt="Firebase" />
          </button>
          <button className="conhecimento" onClick={() => openModal(4)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/java.png" alt="Java" />
          </button>
          <button className="conhecimento" onClick={() => openModal(5)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/js.png" alt="Javascript" />
          </button>
          <button className="conhecimento" onClick={() => openModal(6)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/mongodb.webp" alt="MongoDB" />
          </button>
          <button className="conhecimento" onClick={() => openModal(7)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/mysql.png" alt="MySQL" />
          </button>
          <button className="conhecimento" onClick={() => openModal(8)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/next.png" alt="Next" />
          </button>
          <button className="conhecimento" onClick={() => openModal(9)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/postgres.png" alt="Postgres" />
          </button>
          <button className="conhecimento" onClick={() => openModal(10)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/py.png" alt="Python" />
          </button>
          <button className="conhecimento" onClick={() => openModal(11)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/react.webp" alt="React" />
          </button>
          <button className="conhecimento" onClick={() => openModal(12)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/sass.webp" alt="SASS" />
          </button>
          <button className="conhecimento" onClick={() => openModal(13)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/swift.webp" alt="Swift" />
          </button>
          <button className="conhecimento" onClick={() => openModal(14)}>
            <img className="conhecimento-img" src="./assets/conhecimentos/swiftdata.webp" alt="SwiftData" />
          </button>
        </div>
      </div>
      <div class="projetos">
        <div class="titulo">PROJECTS</div>
        <div class="subtitulo">click the icons to learn more</div>
        <div className="icones">
          <a target="_blank" rel="noreferrer" href="https://agralemos.com.br" className="projeto"
          ><img className="icone" src="./assets/icones/agralemos.png" alt="agra lemos"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/cal-bill" className="projeto"
          ><img className="icone" src="./assets/icones/bill.png" alt="bill"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/climatestoriescal" className="projeto"
          ><img className="icone" src="./assets/icones/climatestories.png" alt="climate stories"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://espacogouvea.com.br" className="projeto"
          ><img className="icone" src="./assets/icones/espacogouvea.png" alt="espaço gouvêa"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://jogbola.com" className="projeto"
          ><img className="icone" src="./assets/icones/jogbola.png" alt="jogbola"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://github.com/caioagralemos/Pagina" className="projeto"
          ><img className="icone" src="./assets/icones/pagina.png" alt="página"
            /></a>
        </div>
        <div className='icones'>
          <a target="_blank" rel="noreferrer" href="https://bit.ly/playpotoca" className="projeto"
          ><img className="icone" src="./assets/icones/potoca.png" alt="potoca"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://revisai.co/en" className="projeto"
          ><img className="icone" src="./assets/icones/revisai.png" alt="revisai"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://caioagralemos.com/rtn" className="projeto"
          ><img className="icone" src="./assets/icones/rtn.png" alt="rtn"
            /></a>
          <a target="_blank" rel="noreferrer" href="http://www.mydjango.kinghost.net/sistema_de_consultas/" className="projeto"
          ><img className="icone" src="./assets/icones/hu.png" alt="sistema de consultas"
            /></a>
          <a target="_blank" rel="noreferrer" href="http://bit.ly/cal-tabooada" className="projeto"
          ><img className="icone" src="./assets/icones/tabooada.png" alt="tabooada"
            /></a>
          <a target="_blank" rel="noreferrer" href="https://www.udemy.com/course/primeiro-contato-com-programacao-python" className="projeto"
          ><img className="icone" src="./assets/icones/udemycourse.png" alt="udemy course"
            /></a>
        </div>
      </div>
      <div class="contato">
        <div class="card">
          <div class="inicio">
            <div class="imagem">
              <img src="./assets/profile-ft.webp" alt="foto de perfil" />
            </div>
            <div class="texto">
              <div class="titulo">Caio Agra Lemos</div>
              <div class="subtitulo">software engineer</div>
            </div>
          </div>
          <div class="final">
            <div class="icones">
              <a target="_blank" rel="noreferrer" href="https://instagram.com/caioagralemos"
              ><img src="./assets/icones/insta2.png" alt="instagram"
                /></a>
              <a target="_blank" rel="noreferrer" href="https://wa.me/5582991220020"
              ><img src="./assets/icones/whatsapp.png" alt="whatsapp"
                /></a>
              <a target="_blank" rel="noreferrer" href="mailto:caioagralemos@live.com"
              ><img src="./assets/icones/email.png" alt="email"
                /></a>
            </div>
            <div class="cv">
              <a href="./assets/en-resume.pdf" download="en-resume.pdf"><img src="./assets/icones/cv.png" alt="download cv" /></a>
              <a href="./assets/en-portfolio.pdf" download="en-portfolio.pdf"><img src="./assets/icones/pf.png" alt="download portfolio" /></a>
            </div>
          </div>
        </div>

        <div class="footer">
          © Caio Agra Lemos. All rights reserved
          <div class="nonstop">Nonstop</div>
        </div>
      </div>
    </>
  );
}