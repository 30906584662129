import React, { useState, useEffect } from 'react';
import './styles/rtn.css'

export default function Rtn() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        <>
            <div className='rtn-home' >
                {windowWidth > 768 ?
                    <img src='./assets/rtn/rtn-logo-website.png' alt='welcome logo' /> :
                    <img src='./assets/rtn/rtn-logo-website-mobile.png' alt='welcome logo' />
                }
            </div>

            <div className='rtn-1' >
                <div className='rtn-content'>
                    <div className='rtntitle'>organize your routine</div>
                    <div className='rtnsubtitle'>it's never too late to start</div>
                    <div className='rtntext'>rtn can help you organize, track, and develop daily and weekly habits. it supports multiple routines and fits seamlessly into your schedule, bringing you closer to your dreams every day. whether you're building a new skill, improving your health, or achieving a personal goal, rtn provides the structure and motivation you need. with its intuitive design, you can easily create routines that align with your lifestyle and aspirations. embrace the journey of self-improvement and watch as small daily actions lead to significant long-term achievements.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store"/></a>

                </div>

                <div>
                    <img src='./assets/rtn/routine.png' alt="organize your routine" />
                </div>
            </div>

            <div className='rtn-2'>
                <div>
                    <img src='./assets/rtn/createairoutine.png' alt="create ai routine" />
                </div>

                <div className='rtn-content'>
                    <div className='rtntitle'>create ai routine</div>
                    <div className='rtnsubtitle'>the smart way to chase dreams</div>
                    <div className='rtntext'>create or edit your own routines, or let the ai do that for you! give it your motivations and we will provide the best steps to get there. and, if you want, you can integrate your daily tasks into it. with rtn, achieving your goals becomes a guided and personalized experience, tailored to your unique needs and aspirations. let the ai help you stay on track and make progress every day.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store"/></a>
                </div>
            </div>

            <div className='rtn-3'>
                <div className='rtn-content'>
                    <div className='rtntitle'>more ai features</div>
                    <div className='rtnsubtitle'>powered by Google Gemini</div>
                    <div className='rtntext'>but that’s not the only thing our ai can do! we can also suggest habits to make your current routine even better, or give you motivational quotes from great people who once had similar dreams. let our ai inspire and guide you, enhancing your journey toward achieving your goals. with tailored suggestions and words of wisdom, you'll stay motivated and on track, making your path to success smoother and more enjoyable. and all of that is powered by google gemini.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store"/></a>
                </div>

                <div>
                    <img src='./assets/rtn/aifeatures.png' alt="more ai features" />
                </div>
            </div>

            <div className='rtn-4'>
                <div>
                    <img src='./assets/rtn/multiplatform.png' alt="fully integrated" />
                </div>

                <div className='rtn-content'>
                    <div className='rtntitle'>fully integrated</div>
                    <div className='rtnsubtitle'>throughout all of your devices</div>
                    <div className='rtntext'>rtn is fully integrated into all of apple's ecosystem. it's available on iPad, iPhone, Watch, and Mac, and it shares live data among all of them. no matter which device you’re using, you’ll always have access to your routines, progress, and motivational insights. stay connected and in sync across all your apple devices, ensuring you never miss a step on your journey to achieving your goals.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store"/></a>
                </div>
            </div>

            <div className='rtn-5'>
                <img src='./assets/rtn/rtn-nameandtext.png' alt='welcome logo' />
            </div>

        <div className="footer">
          <a target="_blank" rel="noreferrer" href="https://caioagralemos.com/en">© Caio Agra Lemos. All rights reserved</a>
        </div>
        </>
    )
}