import React from 'react';
import './styles/privacy.css';

export default function Privacy() {
    React.useEffect(() => {
        document.body.classList.add('privacy');
        document.documentElement.classList.add('privacy');
        return () => {
          document.body.classList.remove('privacy');
          document.documentElement.classList.remove('privacy');
        };
      }, []);
    return (
        <div className='privacy'>
            <img className='background' src='./assets/degrade.png' alt='background' />
            <div className='content'>
                <img src='./assets/favicon.webp' alt='logo' />

                <section>
                    <h1>Privacy Policy</h1>
                    <p>Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you on the website <strong>caioagralemos.com</strong> and other apps we own and operate.</p>
                </section>

                <section>
                    <h2>Information Collection</h2>
                    <p>We only request personal information when we truly need it to provide a service to you. We do so by fair and lawful means, with your knowledge and consent. We also inform you why we are collecting it and how it will be used.</p>
                </section>

                <section>
                    <h2>Data Retention</h2>
                    <p>We retain collected information only for as long as necessary to provide the requested service. When we store data, we protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
                </section>

                <section>
                    <h2>Information Sharing</h2>
                    <p>We do not share personally identifiable information publicly or with third parties, except when required by law.</p>
                </section>

                <section>
                    <h2>Links to External Sites</h2>
                    <p>Our website may contain links to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility for their respective privacy policies.</p>
                </section>

                <section>
                    <h2>Refusal of Personal Information</h2>
                    <p>You are free to refuse our request for personal information, understanding that we may not be able to provide some desired services.</p>
                </section>

                <section>
                    <h2>Acceptance of Privacy Practices</h2>
                    <p>Your continued use of our website and apps will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, please contact us.</p>
                </section>

                <section>
                    <h2>User Commitment</h2>
                    <p>The user agrees to make proper use of the content and information provided by caioagralemos.com on the website and in its apps, including but not limited to:</p>
                    <ul>
                        <li>Not engaging in activities that are illegal or contrary to good faith and public order;</li>
                        <li>Not disseminating propaganda or content of a racist, xenophobic, illegal pornography, terrorism-apologizing, or human rights-violating nature;</li>
                        <li>Not causing harm to the physical (hardware) and logical (software) systems of caioagralemos.com, its suppliers, or third parties, nor introducing or spreading computer viruses or any other systems capable of causing the aforementioned damages.</li>
                    </ul>
                </section>

                <section>
                    <h2>More Information</h2>
                    <p>We hope this policy is clear and, as mentioned earlier, if there is anything you are unsure about whether you need or not, please contact us at caioagralemos@live.com.</p>

                    <small>This policy is effective as of January 1, 2024.</small>
                </section>
            </div>
        </div>
    );
}
