import React, { useState, useEffect } from 'react';
import './styles/rtn.css'

export default function RtnPt() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className='rtn-home' >
                {windowWidth > 768 ?
                    <img src='./assets/rtn/rtn-logo-website.png' alt='welcome logo' /> :
                    <img src='./assets/rtn/rtn-logo-website-mobile.png' alt='welcome logo' />
                }
            </div>

            <div className='rtn-1'>
                <div className='rtn-content'>
                    <div className='rtntitle'>organize sua rotina</div>
                    <div className='rtnsubtitle'>nunca é tarde pra começar</div>
                    <div className='rtntext'>o rtn pode te ajudar a organizar, acompanhar e desenvolver hábitos diários e semanais. ele suporta múltiplas rotinas e se encaixa perfeitamente na sua agenda, aproximando você dos seus sonhos a cada dia. seja construindo uma nova habilidade, melhorando sua saúde ou alcançando um objetivo pessoal, o rtn fornece a estrutura e a motivação que você precisa. com seu design intuitivo, você pode facilmente criar rotinas que se alinham com seu estilo de vida e aspirações. abrace a jornada e veja como pequenas ações diárias levam a conquistas significativas a longo prazo.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/br/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store" /></a>
                </div>

                <div>
                    <img src='./assets/rtn/routine.png' alt="organize sua rotina" />
                </div>
            </div>

            <div className='rtn-2'>
                <div>
                    <img src='./assets/rtn/createairoutine.png' alt="crie uma rotina com ai" />
                </div>

                <div className='rtn-content'>
                    <div className='rtntitle'>crie uma rotina com ai</div>
                    <div className='rtnsubtitle'>uma maneira inteligente de sonhar</div>
                    <div className='rtntext'>crie ou edite suas próprias rotinas, ou deixe a ia fazer isso por você! nos diga suas motivações e nós forneceremos os melhores passos para chegar lá. e, se quiser, você pode integrar suas tarefas diárias atuais com nossas ideias. com o rtn, alcançar seus objetivos se torna uma experiência guiada e personalizada, adaptada às suas necessidades e aspirações únicas.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/br/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store" /></a>
                </div>
            </div>

            <div className='rtn-3'>
                <div className='rtn-content'>
                    <div className='rtntitle'>mais recursos de ia</div>
                    <div className='rtnsubtitle'>powered by Google Gemini</div>
                    <div className='rtntext'>mas isso não é tudo que nossa ia pode fazer! também podemos sugerir hábitos para tornar sua rotina atual ainda melhor, ou fornecer citações motivacionais de grandes pessoas que um dia tiveram sonhos semelhantes. deixe nossa ia te inspirar e guiar, aprimorando sua jornada para alcançar seus objetivos. com sugestões personalizadas e palavras de sabedoria, você se manterá motivado e no caminho certo, tornando sua trajetória rumo ao sucesso mais suave e agradável.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/br/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store" /></a>
                </div>

                <div>
                    <img src='./assets/rtn/aifeatures.png' alt="mais recursos de ai" />
                </div>
            </div>

            <div className='rtn-4'>
                <div>
                    <img src='./assets/rtn/multiplatform.png' alt="totalmente integrado" />
                </div>

                <div className='rtn-content'>
                    <div className='rtntitle'>totalmente integrado</div>
                    <div className='rtnsubtitle'>em todos os seus dispositivos</div>
                    <div className='rtntext'>o rtn está totalmente integrado a todo o ecossistema da Apple. está disponível no iPad, iPhone, Watch e Mac, e compartilha dados em tempo real entre todos eles. não importa qual dispositivo você esteja usando, você sempre terá acesso às suas rotinas, progresso e insights motivacionais. mantenha-se conectado e sincronizado em todos os seus dispositivos Apple, garantindo que você nunca perca um passo na sua jornada para alcançar seus objetivos.</div>
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/br/app/rtn/id6604054997"><img src="./assets/rtn/appstore.png" alt="app store" /></a>
                </div>
            </div>


            <div className='rtn-5'>
                <img src='./assets/rtn/rtn-nameandtext.png' alt='welcome logo' />
            </div>

            <div className="footer">
                <a target="_blank" rel="noreferrer" href="https://caioagralemos.com/">© Caio Agra Lemos. All rights reserved</a>
            </div>
        </>
    )
}