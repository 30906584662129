import React, { useState, useRef } from 'react';
import './styles/privacy.css'

export default function Privacidade() {
    React.useEffect(() => {
        document.body.classList.add('privacy');
        document.documentElement.classList.add('privacy');
        return () => {
          document.body.classList.remove('privacy');
          document.documentElement.classList.remove('privacy');
        };
      }, []);
    return (
        <div className='privacy'>
            <img className='background' src='./assets/degrade.png' alt='background' />
            <div className='content'>
                <img src='./assets/favicon.webp' alt='logo' />

                <section>
                    <h1>Política de Privacidade</h1>
                    <p>A sua privacidade é importante para nós. É política do caioagralemos.com respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <strong>caioagralemos.com</strong> e em outros aplicativos que possuímos e operamos.</p>
                </section>

                <section>
                    <h2>Coleta de Informações</h2>
                    <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>
                </section>

                <section>
                    <h2>Retenção de Dados</h2>
                    <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
                </section>

                <section>
                    <h2>Compartilhamento de Informações</h2>
                    <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
                </section>

                <section>
                    <h2>Links para Sites Externos</h2>
                    <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
                </section>

                <section>
                    <h2>Recusa de Informações Pessoais</h2>
                    <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>

                </section>

                <section>
                    <h2>Aceitação das Práticas de Privacidade</h2>
                    <p>O uso continuado de nosso site e aplicativos será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>
                </section>

                <section>
                    <h2>Compromisso do Usuário</h2>
                    <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o caioagralemos.com oferece no site e em seus aplicativos e com caráter enunciativo, mas não limitativo:</p>
                    <ul>
                        <li>Não se envolver em atividades que sejam ilegais ou contrárias à boa fé e à ordem pública;</li>
                        <li>Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
                        <li>Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do caioagralemos.com, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>
                    </ul>
                </section>

                <section>
                    <h2>Mais Informações</h2>
                    <p>Esperamos que esta política esteja clara e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, entre em contato conosco em caioagralemos@live.com.</p>

                    <small>Esta política é efetiva a partir de 1 de janeiro de 2024.</small>
                </section>
            </div>
        </div>
    )
}